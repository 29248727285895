<template>
    <div>
        <div class="stuContact A4Container flexCloumnCenter pageBreak_no" v-for="(item,index) in info" :key="index">
            <div class="title">与家长、学生联系情况记录</div>
            <div class="form">
                <div class="flexCloumnSingleCenter" v-for="(items,indexs) in item" :key="indexs"
                    style="width: 100%;height: 33.3%;">
                    <el-row class="el-row" style="line-height: 40px;padding: 0 10px;">
                        <el-col :span="6" class="flexStart">学生姓名：{{items.stu_name}}</el-col>
                        <el-col :span="6" class="flexStart">家长姓名：{{items.parent_name}}</el-col>
                        <el-col :span="6" class="flexStart">方式：{{ items.contact_type }}</el-col>
                        <el-col :span="6" class="flexStart">时间：{{items.contact_time}}</el-col>
                    </el-row>
                    <div class="content"
                        style="width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;">
                        <div :id="'divCntnrA_'+index+'_'+indexs" :style="(items.fieldname&&items.fieldname.length>0)?
                                 'width:100%;height: calc( 100% - 130px );overflow: hidden;':
                                 'width:100%;height: 100%;overflow: hidden;'">
                            <div :id="'resizeDivA_'+index+'_'+indexs" style="width: 100%;word-break: break-all;">
                                <span style="font-weight: 700;">访谈情况及意见：</span>{{ items.content }}
                            </div>

                        </div>
                        <div v-if="items.fieldname&&items.fieldname.length>0" class="flexStart"
                            style="width: calc( 100% - 10px );height: 125px;overflow: hidden;position: absolute;bottom: 10px;">
                            <div v-for="(itemss,indexss) in items.fieldname" :key="indexss"
                                style="width: 24%;height: 100%;margin-right: 1%;">
                                <img :src="itemss.fileUrl || itemss.src" alt=""
                                    style="max-width: 100%;max-height: 100%;">
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'contact',
    data () {
        return {
            dataover: false,
            userid: '',
            info:[],
        }
    },
    props: {
        info1: {
            type: Array,
            default: []
        }
    },
    mounted() {
        // console.log(58, 1, )

        // console.log(606060,1,this.info1)
        let _this = this
        setTimeout(function (){
            let info = JSON.parse(JSON.stringify(_this.info1))
            if (info&&info.length>0) {
                info.map((a,ia)=>{
                    if (a&&a.length>0) {
                        a.map((b, ib) => {
                            if (b.content) {
                                b.content = _this.stripscript(b.content)
                            }
                        })
                    }

                })
                _this.info = [ ...info ]

            }
            _this.$nextTick(() => {
                _this.resizeA()
                // _this.resizeB()
            })
        },500)



    },
    methods: {
        stripscript (s) {
            var pattern = new RegExp('\n')
            var rs = ''
            for (var i = 0; i < s.length; i++) {
                rs = rs + s.substr(i, 1).replace(pattern, '')
            }
            return rs
        },
        resizeA () {
           this.info.map((a,index)=>{
               a.map((b,indexs)=>{
                   let id1 = `divCntnrA_${index}_${indexs}`
                   let id2 = `resizeDivA_${index}_${indexs}`

                   let divHeight = document.getElementById(id1).offsetHeight
                   let textHeight = document.getElementById(id2).offsetHeight
                   console.log('a',`${index}_${indexs}`, divHeight, textHeight)
                   let i = 1
                   while (textHeight > divHeight && i < 5) {
                       document.getElementById(id2).style.fontSize = `${16 - i}px`
                       textHeight = document.getElementById(id2).offsetHeight
                       i++

                   }

               })
           })

        },
    }
}
</script>

<style lang="less" type="text/less">
.stuContact .el-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.stuContact .el-row {
    width: 100%;
    padding: 0;
    margin: 0;
}
</style>
<style lang="less" type="text/less" scoped>

.stuContact {
    font-size: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 25px 0;

    .title {
        font-size: 20px;
        line-height: 50px;
    }

    .form {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        border: 1px solid #000;
        border-top: 0;

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .content {
            width: 100%;
            height: 100%;
            padding: 10px;
            white-space: break-spaces;
            word-break: break-all;
            overflow: hidden;
        }

        .el-row {
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
</style>
